<template>
	<div id="app">
		<h2>Notification Test 23</h2>
		<button @click="checkNotifications">Allow</button>
    <p>{{ notificationPermissionsGranted }}</p>
    <p>{{ notificationTokenReceived }}</p>
	</div>
</template>

<script>
/* eslint-disable */
import HelloWorld from './components/HelloWorld.vue'
import { getMessaging, getToken, onMessage } from "firebase/messaging";

export default {
  name: 'App',
  components: {
    HelloWorld
  },
  data () {
    return {
      notificationPermissionsGranted: false,
      notificationTokenReceived: '',
    }
  },
  mounted () {
    setTimeout(() => {
      this.getNotificationToken();  

    }, 1000);
  },
  methods: {
    getNotificationToken () {
      const messaging = getMessaging();
      getToken(messaging, { vapidKey: "BDSUwpPnlaj9h0BF-AVAht-dhb3oO7FEUi9DSEG8aiJCk1nlPzsnydqnflX4YyOKjrL2vb2NcxDXz-wzMr97Qm8" }).then((currentToken) => {
        if (currentToken) {
          console.log(currentToken);
          this.notificationTokenReceived = currentToken;
          onMessage(messaging, (payload) => {
            console.log('Message received. ', payload);
          });
        } else {
          // Show permission request.
          console.log('No Instance ID token available. Request permission to generate one.');

        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
    },
    checkNotifications () {
      console.log("checkNotifications")
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
            this.notificationPermissionsGranted = true;
            this.getNotificationToken();
        } else {
          this.notificationPermissionsGranted = false;
          console.log('Unable to get permission to notify.');
        }
      });
    }
  }
}
</script>

<style>
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	margin-top: 60px;
}
</style>

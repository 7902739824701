import { initializeApp } from "firebase/app";
import { getMessaging } from 'firebase/messaging'

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBAqMojGHyUdqFWGE-pmRkCmVWzfGB0bnc",
  authDomain: "xc-debug.firebaseapp.com",
  projectId: "xc-debug",
  storageBucket: "xc-debug.appspot.com",
  messagingSenderId: "1011206255747",
  appId: "1:1011206255747:web:11b4753879cbeb21a497b6"
};

var app = initializeApp(firebaseConfig)
const messaging = getMessaging(app)

export default messaging